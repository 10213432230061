.EasyLoginRequired {
  // background: #d93848;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  width: 100vw;
  // gradiant backbround #FFFFFF to #41c6ff opacity 21.5
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(65,198,255,0.215) 100%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &__Wrapper {
    max-width: 38rem;
  }
  .ui.header {
    span {
        font-family: 'Lobster Two', sans-serif;
        font-weight: 700;
        color: #8D5AA8;
        font-size: 2.16rem;
    }
  }
  .ui.violet {
    background-color: #8D5AA8;
  }
}
