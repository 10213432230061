.Invitation {
  // background: #d93848;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  // gradiant backbround #FFFFFF to #41c6ff opacity 21.5
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  &__Background {
    background: url("../../Assets/BackgroundSVG.svg");
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    opacity: 1;
    z-index: 1;
  }
  &__Gradient {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(65, 198, 255, 1) 100%);
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    opacity: 1;
    z-index: 0;
  }
  &__Curved {
    margin-top: 2rem;
    text {
      &, & * {
        font-family: 'Lobster Two', sans-serif;
        fill: #8D5AA8;
        font-size: 3rem;
      }
      font-weight: 700;
    }
  }
  &__Wrapper {
    max-width: min(38rem, 85vw);
    min-width: min(500px, 85vw);
    width: 100%;
    height: 100vh;
    z-index: 10;
    div:last-child::after{
      // content: '';
      // width: 100%;
      // height: 24rem;
      
    }
  }
  &__Logo {
    img {
      width: 100%;
      max-height: 20vh;
    }
  }
  &__Starred{
    color: #FFF;
    height: 4rem;
    margin-top: 1rem;
    width: min(500px, 85vw);
    font-size: 2rem;
    line-height: 4rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    // width: min(500px, 90vw);
    &__Left {
      // background-color: #8D5AA8;
      width: 2rem;
      height: 4rem;
      border-top: 2rem solid #8D5AA8;
      border-left: 2rem solid transparent;
      &::after {
        width: 0;
        height: 0;
        border-bottom: 2rem solid #8D5AA8;
        border-left: 2rem solid transparent;
        content: '';
        position: absolute;
        margin-left: -2rem;
      }
    }
    &__Content {
      background-color: #8D5AA8;
      flex-grow: 10;
      font-family: 'Lobster Two', sans-serif;
    }
    &__Right {
      width: 2rem;
      height: 4rem;
      border-top: 2rem solid #8D5AA8;
      border-right: 2rem solid transparent;
      &::after {
        width: 0;
        height: 0;
        border-bottom: 2rem solid #8D5AA8;
        border-right: 2rem solid transparent;
        content: '';
        position: absolute;
      }
    }
    &::after {
      background: url("../../Assets/Starred_Back.svg");
      content: '';content: "";
      position: absolute;
      height: 180px;
      min-width: min(360px, 75vw);
      max-width: 440px;
      z-index: -1;
      background-repeat: no-repeat;
      opacity: 0.75;
    }
  }
  &__Date {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    &__Day {
      text-align: right;
      line-height: 2.55rem;
    }
    &__Hour {
      text-align: left;
      line-height: calc(2.5rem * 2);
      border-left: 3px solid #EEB5EB;
      padding-left: 2rem;
    }
    &__Day,
    &__Hour{
      font-size: 2.25rem;
      font-weight: bold;
      color: #8D5AA8;
      font-family: 'Lobster Two', sans-serif;
    }
  }
  &__Place {
    background: #FFF;
    border-radius: 1.5rem;
    padding: 1.5rem;
    padding-bottom: 1rem !important;
    & > span {
      font-size: 2.5rem;
      font-weight: bold;
      color: #8D5AA8;
      font-family: 'Lobster Two', sans-serif;
      display: flex;
      flex-direction: column;
      line-height: 1.5rem;
      small {
        margin-top: 0.75rem;
        font-size: 1rem;
        font-weight: 300 !important;
      }
    }
    &__Buttons {
      margin-top: 1rem;
      display: flex;
      gap: 16px;
    }
    &__Selector {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      gap: 18px;
      &__Field {
        display: flex;
        flex-direction: column;
        flex-grow: 10;
      }
    }
  }
  &__Confirm {
    &__Title {
      color: #D44E87;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
  &__Footer {
    background: url("../../Assets/Footer.png") top center no-repeat;
    background-size: cover;
    z-index: 12;
    width: 100vw;
    min-height: 150px;
    left: 0;
    position: fixed;
    bottom: 0;
    img {
      &:first-child {
        height: 150px;
        position: absolute;
        left: 3rem;
        bottom: 0;
      }
      &:last-child {
        height: 150px;
        position: absolute;
        right: 3rem;
        bottom: 0;
      }
    }
  }
  &__Clearfix {
    width: 100%;
    min-height: 12rem;
  }
  .ui.header {
    span {
        font-family: 'Lobster Two', sans-serif;
        font-weight: 700;
        color: #8D5AA8;
        font-size: 2.16rem;
    }
  }
  .ui.violet {
    background-color: #8D5AA8;
  }
}
