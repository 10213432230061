body {
  // background: #E5E5E5;
  height: auto !important;
  font-family: "Raleway", sans-serif;
}

.rdw-editor-wrapper {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
}

* {
  font-family: "Raleway", sans-serif;
}

a {
  color: #8D5AA8 !important;
}

html {
  scroll-behavior: smooth;
}